<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  @successPost="$router.push('/masters/users/laboran')"
                  routeBack="/masters/users/laboran"
                  roleId="3"
                  :form="form"
                  :routePost="`api/users/${form.id}`"
                  :currentPhoto="form.photo"
                  purpose="edit"
                  secondPurpose="special-user"
                  messageSuccess="Berhasil mengubah laboran"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/users/Form.vue";
import module from "@/core/modules/CrudModule.js";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        address: "",
        email: "",
        name: "",
        nickname: "",
        phone: "",
        photo: null,
        description: "",
        role_id: "",
        school_id: "",
        password: "",
        registration_number: "",
      },
      current_photo: null
    };
  },
  methods: {
    async get() {
      this.form = await module.get("api/users/" + this.$route.params.id);
      //("get user by id");
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/masters/users");
      } else {
        this.form["_method"] = "put";
        this.dataLoaded = true;
        this.current_photo = this.form.photo

        if (this.form.role_id == 0) {
          this.form.role_id = "";
        }
        if (this.form.grade_id == 0) {
          this.form.grade_id = "";
        }
        if (this.form.school_id == 0) {
          this.form.school_id = "";
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laboran", route: "/masters/users/laboran" },
      { title: "Edit Laboran" },
    ]);

    this.get()

    //("Edit guru==============================");
  },
};
</script>